/* eslint-env browser */
import 'core-js';
import 'regenerator-runtime/runtime';

import Url from 'url';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import Plausible from 'plausible-tracker';

import { Flex, Box } from 'reflexbox';

import 'shared/axiosRetry';
import SharedConfig from 'shared/config';
import { setVersions, preload as loadGameData } from 'shared/gameData';

import config from 'frontend/config';
import { retry, dlog, isBetaSite } from 'frontend/util';


import DefaultErrorBoundary, { DefaultError } from 'frontend/components/DefaultErrorBoundary';
import RaidbotsSkull from 'frontend/components/RaidbotsSkull';

const docElementTranslated = () => !!document.documentElement.className.match('translated');

let isGoogleTranslated = docElementTranslated();

const observer = new MutationObserver(() => {
  if (docElementTranslated()) {
    // eslint-disable-next-line no-console
    console.log('Google Translate detected, disabling error reporting');
    isGoogleTranslated = true;
  }
});

observer.observe(document.documentElement, {
  attributes: true,
  attributeFilter: ['class'],
  childList: false,
  characterData: false,
});

Sentry.init({
  dsn: 'https://7b5eb09d382745929edd40ca028764bf@sentry.io/123015',
  environment: process.env.NODE_ENV || 'development',
  release: `frontend-${process.env.VERSION}`,
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),

    Sentry.extraErrorDataIntegration,
  ],
  beforeSend: (event, hint) => {
    // ignore google translate page loads
    if (isGoogleTranslated) {
      return null;
    }

    // ignore anything that looks like an extension by inspecting the stack trace
    if (hint?.originalException?.stack?.match(/chrome-extension/)) {
      return null;
    }

    return event;
  },
  allowUrls: [
    /https?:\/\/((ulduar|beta|www|mimiron)\.)?raidbots\.com/,
  ],
  includePaths: [
    /https?:\/\/((ulduar|beta|www|mimiron)\.)?raidbots\.com/,
  ],

  // only capture replay on error
  replaysSessionSampleRate: 0.0,
  replaysOnErrorSampleRate: 1.0,

  ignoreErrors: [
    // google translate extension error
    'a[b].target.className.indexOf is not a function',

    // misc errors that are probably extensions, weird browser versions, or non-fatal edge cases
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications.',
    'NotFoundError: Failed to execute \'removeChild\' on \'Node\': The node to be removed is not a child of this node.',
    'Unexpected end of input',
    'hideMyLocation',
    'Unexpected token \'else\'',
    'Unexpected end of input',
    'The node before which the new node is to be inserted is not a child of this node.',
    'The node to be removed is not a child of this node',
    'can\'t redefine non-configurable property "userAgent"',
    'Should not already be working.',
    'adb_rotate?', // some .ru domain XHR, probably from an extension
    'undefined is not an object (evaluating \'webkit.messageHandlers.loginsManagerMessageHandler.postMessage\')',
    'Cannot redefine property: BetterJsPop',
    'Failed to execute \'replaceState\' on \'History\'',
    'Cannot redefine property: googletag',
    // microsoft crawler triggering a recursive error?
    'Non-Error promise rejection captured with value: Object Not Found Matching Id',
    'window.bannerNight is not a function',
    '$ is not defined',
    'www-raidbots-com.translate.goog',
    'chrome-extension://',
    // some random safari extension
    'Error response received for message <page-ready>',

    // List of common ignores from https://docs.sentry.io/clients/javascript/tips/

    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    'Can\'t find variable: ZiteReader',
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    // Generic error code from errors outside the security sandbox
    // You can delete this if using raven.js > 1.0, which ignores these automatically.
    'Script error.',
    // Avast extension error
    '_avast_submit',
    'Identifier \'change_ua\' has already been declared',

    // Sincera crawler
    'apstagLOADED is not defined',
    '__cmp is not defined',
    'googlefc is not defined',
    'moat_px is not defined',

    'navigator.geolocation is undefined',

    'Cannot read properties of undefined (reading \'mvpConfig\')',
    'YT is not defined',
    'dailymotion is not defined',
    'jwDefaults is not defined',
    'IMUID is not defined',

    // from OrangeMonkey extension
    'reading \'firefoxSample\'',

    // Jan 2025 - tons of errors being repeatedly spammed and I can't determine the source
    'Illegal invocation',
    'getElementsByTagName',
  ],
  denyUrls: [
    // Google Adsense
    /pagead\/js/i,
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
});

const plausible = Plausible({
  domain: 'raidbots.com',
});


const url = Url.parse(window.location.href, true);
const headless = Boolean(url.query.headless);

if (process.env.NODE_ENV === 'production') {
  // use testMode to avoid tracking puppeteer requests
  if (!headless) {
    plausible.enableAutoPageviews();
  }
}

class BootScreen extends React.Component {
  state = {
    show: false,
  };

  componentDidMount() {
    this.timeout = setTimeout(() => {
      this.setState({ show: true });
    }, 500);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  render() {
    if (!this.state.show) {
      return null;
    }
    return (
      <Flex justify="center" align="center" column style={{ height: '100%' }}>
        <Box>
          <RaidbotsSkull fill="#444" />
        </Box>
        <Box
          style={{
            color: '#555',
            fontFamily: config.fontFamily,
            textAlign: 'center',
          }}
        >
          Loading...
        </Box>
      </Flex>
    );
  }
}

const RaidbotsApp = React.lazy(() => retry(() => import('./app')));
const LazyApp = () => {
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);

  React.useEffect(async () => {
    try {
      const version = window.config.gameDataVersion;
      const fallbackVersion = isBetaSite() ? SharedConfig.wowEnvMap.test : SharedConfig.wowEnvMap.live;
      dlog('Game data version:', version);
      const start = Date.now();
      setVersions(version, fallbackVersion);
      await loadGameData();
      const duration = Date.now() - start;
      dlog(`Game data loaded in ${duration.toLocaleString()}ms`);
      setLoading(false);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      Sentry.captureException(err);
      setError(err);
    }
  }, []);

  if (error) {
    return (
      <DefaultError
        title="Unable to load game data"
        description="Raidbots was unable to load critical data files"
      />
    );
  }

  if (loading) {
    return (
      <BootScreen />
    );
  }

  return (
    <DefaultErrorBoundary>
      <React.Suspense fallback={<BootScreen />}>
        <RaidbotsApp />
      </React.Suspense>
    </DefaultErrorBoundary>
  );
};

const App = LazyApp;

ReactDOM.render(
  <App />,
  document.getElementById('app'),
);

/*
if (module.hot) {
  module.hot.accept('./components/Router', () => {
    const HotApp = hot(module)(require('./components/Router').default); // eslint-disable-line global-require
    ReactDOM.render(
      <Provider store={store}>
        <HotApp />
      </Provider>,
      document.getElementById('app'),
    );
  });
}
*/

window.raidbots = {
  version: process.env.VERSION,
  env: process.env.NODE_ENV,
};
